import { GeolocationContext } from '@components/hooks/GeolocationProvider'
import { URL_PARAM_STORAGE_KEY } from '@lib/browsers/cookies/extractTrackingOptionsFromUtmParams'
import getTrialApplicationUrl from '@lib/routes/helpers/getTrialApplicationUrl'
import type { FormData } from '@lib/types/FormData'
import type { Geolocation } from '@lib/types/Geolocation'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import isNil from 'lodash/isNil'
import { useContext } from 'react'
import { useCookies } from 'react-cookie'

/**
 * Provide a URL for Trial Application Form for particular trial, take TrialAssociation object as parameter.
 */

export default function useTrialApplicationLink(
  trial?: Pick<TrialAssociation, 'nctId' | 'overallStatus' | 'slug'>,
): string {
  const [urlParamCookie] = useCookies([URL_PARAM_STORAGE_KEY])
  const userLocation = useContext(GeolocationContext)

  const patientTrialApplicationPrefilledParams =
    buildPatientTrialApplicationPrefilledParams(
      extractGeolocationFromLocation(userLocation),
    )

  const trialApplicationLink = trial
    ? getTrialApplicationUrl({
        cookies: urlParamCookie,
        patientTrialApplicationPrefilledParams,
        trial,
      })
    : ''

  return trialApplicationLink
}

function buildPatientTrialApplicationPrefilledParams(
  ...args: FormData[]
): FormData {
  return args.reduceRight((allData, datum) => {
    return { ...allData, ...datum }
  })
}

function extractGeolocationFromLocation(userLocation: Geolocation): FormData {
  if (!isNil(userLocation.latitude) && !isNil(userLocation.longitude)) {
    return {
      latitude: userLocation.latitude,
      longitude: userLocation.longitude,
    }
  }

  return {}
}
