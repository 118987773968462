import { Text } from '@components/primitives/Text'
import type { TextStyle } from '@components/primitives/Text/fontStyles'
import { classMerge } from '@components/utilities/classMerge'
import type { Color, TextColor } from '@lib/colors'
import Tippy from '@tippyjs/react'
import type { ReactNode } from 'react'
import type { Placement } from 'tippy.js'

export type TooltipProps = {
  backgroundColor?: Color
  className?: string
  delay?: number | [number, number]
  description?: string | ReactNode
  descriptionClassName?: string
  duration?: number
  inlineTooltip?: boolean
  interactive?: boolean
  label: string | ReactNode
  labelColor?: TextColor
  labelStyleName?: TextStyle
  placement?: Placement
  underline?: boolean
}

export const TOOLTIP_BACKGROUND_DEFAULT_COLOR = 'white'

export const Tooltip = ({
  backgroundColor = TOOLTIP_BACKGROUND_DEFAULT_COLOR,
  className,
  delay = 0,
  description,
  descriptionClassName,
  duration = 0,
  inlineTooltip,
  interactive,
  label,
  labelColor,
  labelStyleName,
  placement,
  underline = true,
}: TooltipProps) => {
  const bgClass = `bg-${backgroundColor}`
  return (
    <Tippy
      className={classMerge(`cursor-pointer`, className)}
      content={
        description ? (
          <div
            className={classMerge(
              bgClass,
              `z-tooltip whitespace-normal rounded-lg border border-lightGrey900 p-4 text-left shadow-lg`,
              descriptionClassName,
            )}
          >
            {description}
          </div>
        ) : undefined
      }
      delay={delay}
      duration={duration}
      interactive={interactive}
      placement={placement}
    >
      <span>
        <Text
          className={classMerge(
            'cursor-pointer',
            underline ? 'underline' : '',
            inlineTooltip ? 'inline' : '',
            labelColor,
          )}
          styleName={labelStyleName}
          value={label}
        />
      </span>
    </Tippy>
  )
}
